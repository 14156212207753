<template>
	<b-row>
		<b-col>
			<loading-data :loading="profile.data.loading" />
			<address-country-form
				v-if="!profile.data.loading"
				:address="ADDRESSES.shipping"
				:user-country="SHOW_USER_COUNTRY"
				:country-code-import="form.country_code"
				:form-import="form"
				:display-override-toggle="showOverrideValidation"
				:errors-import="errors.errors"
				@on-submit="newAddress" />
		</b-col>
	</b-row>
</template>
<script>

import AddressCountryForm from '@/components/AddressCountryForm';
import { Profile as ProfileMessages, Validations, Addresses } from '@/translations';
import AddressBook from '@/util/AddressBook';
import Profile from '@/util/Profile';
import { SHOW_USER_COUNTRY } from '@/settings/Country';
import { SYSTEM_ALERT, UNPROCESSABLE } from '@/settings/Errors';
import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import LoadingData from '@/components/Loading';
import { ADDRESSES } from '@/settings/AddressBook';

export default {
	name: 'NewAddress',
	messages: [ProfileMessages, Validations, Addresses],
	components: { AddressCountryForm, LoadingData },
	data() {
		return {
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				city: '',
				postcode: '',
				country_code: '',
				region_id: '',
				override_validation: false,
			},
			addressBook: new AddressBook(),
			getSuggestion: new AddressBook(),
			profile: new Profile(),
			alert: new this.$Alert(),
			SHOW_USER_COUNTRY,
			showOverrideValidation: false,
			addressVerificationCountries,
			ADDRESSES,
		};
	},
	computed: {
		errors() {
			return this.addressBook.data.errors;
		},
		profileData() {
			try {
				const { data } = this.profile.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		countryCode() {
			try {
				const { country } = this.profileData.attributes;
				return country;
			} catch (error) {
				return '';
			}
		},
		username() {
			try {
				return this.profileData.attributes.username;
			} catch (error) {
				return {};
			}
		},
	},
	watch: {
		username(user) {
			this.$parent.titleParam = { user };
		},
	},
	mounted() {
		this.profile.getProfile(this.$route.params.userId).then(() => {
			this.form.country_code = this.profileData.attributes.country;
		});
	},
	methods: {
		newAddress(form) {
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'));

			const { userId } = this.$route.params;

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			this.addressBook.saveAddress(userId, this.objectToFormData(payload)).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });
				let routeName = 'UpdateCustomer';
				let routeParams = { customerId: userId };

				if (this.$route.name === 'AddDistributorAddress') {
					routeName = 'DistributorsInformation';
					routeParams = { distributorId: userId };
				}

				this.$router.push({ name: routeName, params: routeParams });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.errors.status)) {
					Object.keys(this.errors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								const options = {
									config: {
										icon: 'error',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
									this.newAddress(this.form);
								}).catch(() => {});
							}).catch(() => {});
						} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
	},
};
</script>
